import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login/login.component';
import { AuthenticationRoutingModule } from './authentication-routing.module';
import { GoogleSigninButtonModule } from '@abacritt/angularx-social-login';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDividerModule } from '@angular/material/divider';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { CustomOtpComponent } from '../components/custom-otp/custom-otp.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { TwoFactorVerificationComponent } from './two-factor-verification/two-factor-verification.component';
import { InputTextModule } from 'primeng/inputtext';
import { PasswordModule } from 'primeng/password';
import { DividerModule } from 'primeng/divider';
import { ButtonModule } from 'primeng/button';
// import { LoginWrapperComponent } from './login-wrapper/login-wrapper.component';
// import { AutoFocusModule } from 'primeng/autofocus';

@NgModule({
  declarations: [
    // LoginComponent,
    // ForgotPasswordComponent,
    CustomOtpComponent,
    ResetPasswordComponent,
    // TwoFactorVerificationComponent,
  ],
  imports: [
    CommonModule,
    AuthenticationRoutingModule,
    // GoogleSigninButtonModule,
    FormsModule,
    MatFormFieldModule,
    MatDividerModule,
    MatSelectModule,
    MatIconModule,
    MatInputModule,
    MatButtonModule,
    ReactiveFormsModule,
    // ----------------
    InputTextModule,
    PasswordModule,
    DividerModule,
    ButtonModule,
    // LoginWrapperComponent

    // AutoFocusModule,
  ],
  // exports: [LoginComponent],
})
export class AuthenticationModule {}
