<div class="login-wrapper">
  <div class="sections row">
    <div class="left-section">
      <div class="seconize-logo">
        <img
          style="width: 150px"
          src="../../../assets/images/login/seconize-logo.png"
          alt=""
        />
      </div>
      <div class="derisk-logo">
        <img
          style="width: 150px"
          src="../../../assets/images/login/logo-new.png"
          alt=""
        />
        <div class="welcome-text">
          @if(page === 'login'){
          <span>Welcome Back!</span> 👋 }@else if(page === 'forgot-pwd'){
          <span>Forgot Password?</span> 🤔 }@else if(page === 'otp'){
          <span>Verify OTP</span> 📲 }
        </div>
      </div>

      <!-- Components (Login, Forgot Password, Verifty OTP) -->
      <ng-content></ng-content>
    </div>

    <div class="right-section">
      <img
        src="../../../assets/images/login/login-side-img.svg"
        alt="placeholder-charts"
        class="placeholder-charts"
      />
    </div>
  </div>
</div>
