import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-login-wrapper',
  standalone: true,
  imports: [],
  templateUrl: './login-wrapper.component.html',
  styleUrl: './login-wrapper.component.scss',
})
export class LoginWrapperComponent {
  @Input() page?: any;
}
