<input
      #digit
      *ngFor="let digit of digits; let i = index"
      type="text" class="p-inputtext-sm"
      inputmode="number"
      [disabled]="disabled"
      [value]="digit"
      (keydown)="onInput(i, $event)"
      (paste)="onPaste($event)"
      autofocus="i==0 ? true : false"
    />