import { Component, ElementRef, QueryList, ViewChildren, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-custom-otp',
  templateUrl: './custom-otp.component.html',
  styleUrl: './custom-otp.component.scss',
  providers: [
    {
      useExisting: forwardRef(() => CustomOtpComponent),
      provide: NG_VALUE_ACCESSOR,
      multi: true,
    },
  ],
})
export class CustomOtpComponent implements ControlValueAccessor {
  private _isDisabled = false;

  @ViewChildren('digit')
  inputs!: QueryList<ElementRef<HTMLInputElement>>;

  digits: any[] = [null, null, null, null, null,null];

  get disabled() {
    return this._isDisabled;
  }

  _onTouched!: () => void;
  _onChange!: (value: string | null) => void;

  writeValue(obj: any): void {
    if (typeof obj !== 'string') {
      return;
    }

    if (obj.length !== this.digits.length) {
      return;
    }

    this.digits = obj.split('');
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this._isDisabled = isDisabled;
  }

  onInput(index: number, $event: Event) {
    const ev = $event as KeyboardEvent;
    const digitInput:any = this.inputs.get(index)?.nativeElement;
    const keyCode = ev.key;

    // skip paste because it's handled in another method
    if (ev.metaKey && keyCode === 'v') {
      return;
    }

    if (['Backspace', 'Tab'].indexOf(keyCode) >= 0) {
      // backspace should delete the current value
      if (keyCode === 'Backspace' && !digitInput?.value) {
        const el:any = this.inputs.get(index - 1)?.nativeElement;
        if (el) {
          el.value = null;
        }
        this.emitChange();
        el?.focus();
      }

      return;
    }

    $event.preventDefault();

    if (Number.isNaN(Number(keyCode))) {
      return;
    }

    digitInput.value = keyCode;
    this.emitChange();
    this.inputs.get(index + 1)?.nativeElement.focus();
  }

  onPaste($event: ClipboardEvent) {
    const numbers = ($event.clipboardData?.getData('text') ?? '').slice(
      0,
      this.digits.length
    );

    if (/\d{6}/.test(numbers)) {
      this.digits = numbers.split('');
      this._onChange(numbers);
    } else {
      $event.preventDefault();
    }
  }

  private emitChange() {
    const value: string = this.inputs
      .map((i) => i.nativeElement.value)
      .join('');
    if (value.length === 0) {
      this._onChange(null);
    } else if (value.length === 6) {
      this._onChange(value);
    }
  }

}
