import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Utils } from '../shared/utils';
import { environment } from '../../environments/environment';
import { catchError, Observable, throwError } from 'rxjs';
import { LoaderService } from '../shared/genric-service/loader.service';

@Injectable({
  providedIn: 'root'
})
export class ResetPasswordService {

  constructor(private http: HttpClient, 
    private utils: Utils,
    private loaderService: LoaderService
  ) { }

  activatePasword(body: any, code: any, username: any, loader: boolean = true): Observable<any> {
    this.loaderService.display(loader);
    return this.http.post(environment.url + '/identity/users/' + username + '/' + code + '/setUserPassword', body,
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          "X-Server-Select": this.utils.getUserSchemaFromUsername(username)
        })
      });
  }
  resetPasword(body: any, code: any, username: any, loader: boolean = true): Observable<any> {
    this.loaderService.display(loader);
    return this.http.post(environment.url + '/identity/users/' + username + '/' + code + '/resetPassword', body,
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          "X-Server-Select": this.utils.getUserSchemaFromUsername(username)
        })
      });
  }
  //Get user obj by emailID
  findIsUserActive(userName: any): Observable<any> {
    return this.http.get<any>(environment.url + '/identity/users/' + userName + '/isUserActive', {
      headers: new HttpHeaders({
        "X-Server-Select": this.utils.getUserSchemaFromUsername(userName)
      })
    })

      .pipe(catchError((e: HttpErrorResponse): any => { return throwError(this.utils.errorHandler(e)); }))
  }}
