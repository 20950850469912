import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoginService } from '../../services/login.service';
import { PASSWORD_REGEX } from '../../shared/pattern';
import { SnackbarService } from '../../shared/services/snackbar.service';
import { ResetPasswordService } from '../../services/reset-password.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrl: './reset-password.component.scss'
})
export class ResetPasswordComponent implements OnInit {
  newPasswordForm: FormGroup = this.fb.group({});
  oldPasswordVisible:boolean = false;
  passwordVisible: boolean = false;
  confirmPasswordVisible: boolean = false;
  expired: boolean = false;
  isPageLoading: boolean = false;
  isUserActivated: boolean = false;
  activationCode: any;
  isActivation: any;
  userName: any;
  isSuccess:boolean = false;

  constructor(private fb: FormBuilder,
    private loginService: LoginService,
    private toaster: SnackbarService,
    private resetPaswordService: ResetPasswordService,
    private route: ActivatedRoute,
    private router: Router) {
    if (location.pathname == "/auth/resetPassword") {
      this.route.queryParams.subscribe((params) => {
        let emailId = params["email"];


        if (emailId != "" && emailId != null && emailId != "undefined") {
          this.resetPaswordService.findIsUserActive(emailId).subscribe((data: any) => {
            if (params["expired"]) {
              this.expired = true
              this.newPasswordForm.controls['oldPassword'].setValidators([Validators.required])
            }
            else {
              this.expired = false

            }
            if (
              data.success &&
              data.data != null &&
              data.data.status == "INVALID"
            ) {
              this.isUserActivated = true;
              this.isPageLoading = false
            } else {
              this.isUserActivated = false;
              this.isUserActivated = true;
              this.isPageLoading = false
            }
          });
        }

      });
    }
  }
  ngOnInit(): void {
    this.route.queryParams.subscribe((params: Params) => {
      this.userName = params['email'];
      this.activationCode = params['acode'];
      this.isActivation = params['isActivation'];
    });
    this.initChangePasswordForm()
  }
  private initChangePasswordForm() {
    this.newPasswordForm = this.fb.group({
      oldPassword: null,
      newPassword: ['', [Validators.required, Validators.pattern(PASSWORD_REGEX), Validators.maxLength(24), Validators.minLength(8)]],
      confirmNewPassword: ['', [Validators.required]],
    },
      { validator: this.passwordMatchValidator })
  }

  private passwordMatchValidator(frm: FormGroup) {
    return frm.controls['newPassword'].value === frm.controls['confirmNewPassword'].value ? null : { 'mismatch': true };
  }
  changePassword() {
    if (this.newPasswordForm.valid) {

      let resetPwBody: any = {
        confirmPassword: this.newPasswordForm.controls['confirmNewPassword'].value,
        password: this.newPasswordForm.controls['newPassword'].value,
      };
      if (this.expired) {
        resetPwBody.oldPassword = this.newPasswordForm.controls['oldPassword'].value
      }

      const serviceMethod = this.expired ? 'resetPasword' : 'activatePasword';
      this.resetPaswordService[serviceMethod](
        resetPwBody,
        this.activationCode,
        this.userName
      ).subscribe((data: any) => {
        this.isPageLoading = false
        if (data.success) {
          this.newPasswordForm.reset();
          this.toaster.success(data.message, '', 3000);
          this.isSuccess = true;
          setTimeout(() => {
            this.router.navigate([environment.routePrefix ? '/' + environment.routePrefix + '/auth/login' : '/auth/login']);
          }, 1000);
        } else {
          this.toaster.error(data.message, '', 3000);
        }
      });
    }

  }
  navigateToLogin(){
    this.router.navigate([environment.routePrefix ? '/' + environment.routePrefix + '/auth/login' : '/auth/login']);
  }
}
