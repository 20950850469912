import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  Input,
  input,
  OnInit,
  Renderer2,
} from '@angular/core';
import { OKTA_AUTH, OktaAuthStateService } from '@okta/okta-angular';
import OktaAuth, { AuthState } from '@okta/okta-auth-js';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { filter, map, Observable } from 'rxjs';
import { EventMessage, EventType } from '@azure/msal-browser';
import { NgxSpinnerService } from 'ngx-spinner';
import { LoginService } from '../../services/login.service';
import { ReCaptchaV3Service, RecaptchaErrorParameters } from 'ng-recaptcha';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { SnackbarService } from '../../shared/services/snackbar.service';
import { environment } from '../../../environments/environment';
import { GenericService } from '../../shared/genric-service/generic.service';
import { Router } from '@angular/router';
import { RedirectService } from '../../shared/services/redirect.service';
import { Utils } from '../../shared/utils';
import { MatDialog } from '@angular/material/dialog';
import {
  GoogleLoginProvider,
  GoogleSigninButtonModule,
  SocialAuthService,
} from '@abacritt/angularx-social-login';
import { NUMBER_SIX_DIGITS_ONLY } from '../../shared/pattern';
import e from 'express';
import { InputTextModule } from 'primeng/inputtext';
import { PasswordModule } from 'primeng/password';
import { DividerModule } from 'primeng/divider';
import { ButtonModule } from 'primeng/button';
import { LoginWrapperComponent } from '../login-wrapper/login-wrapper.component';
import { SelectButtonModule } from 'primeng/selectbutton';

// declare const Google: any;
@Component({
  selector: 'app-login',
  standalone: true,
  imports: [
    LoginWrapperComponent,
    GoogleSigninButtonModule,
    InputTextModule,
    PasswordModule,
    DividerModule,
    ButtonModule,
    SelectButtonModule,
    ReactiveFormsModule,
  ],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss',
})
export class LoginComponent implements OnInit, AfterViewInit {
  isSSO: boolean = false;
  isIframe = false;
  isPageLoading: boolean = false;
  singleBu: boolean = false;
  recaptchaInterval: any;
  buttonWidth = 150;
  reCaptchaResponse: any = '';
  enableSubmitButton: boolean = true;

  is2FAPage: boolean = false;
  passwordVisible: boolean = false;
  token: any = '';
  user: any;
  privileges: any;
  profileImageUrl: string = '';
  navItems: any[] = [];
  filteredItems: any[] = [];
  isLoginDisabled: boolean = true;
  // isASMBranch: boolean = environment.is_asm_branch;
  otp = new FormControl(null, [
    Validators.required,
    Validators.pattern(NUMBER_SIX_DIGITS_ONLY),
  ]);
  showDropdown: boolean = false;

  loginForm: FormGroup;
  // googleClientId = environment.googleClientId;

  servers = [
    { label: 'Seconize', value: 'SECONIZE' },
    { label: 'Larsen Toubro', value: 'LNT' },
  ];

  constructor(
    private router: Router,
    private _oktaStateService: OktaAuthStateService,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private spinner: NgxSpinnerService,
    private loginService: LoginService,
    private recaptchaV3Service: ReCaptchaV3Service,
    @Inject(OKTA_AUTH) private _oktaAuth: OktaAuth,
    private fb: FormBuilder,
    private toaster: SnackbarService,
    private el: ElementRef,
    private genericService: GenericService,
    private redirectService: RedirectService,
    private utils: Utils,
    private dialog: MatDialog,
    private g_authService: SocialAuthService,
    private renderer: Renderer2
  ) {
    localStorage.clear();

    this.loginForm = this.fb.group({
      email: [
        '',
        [Validators.required, this.emailValidator(), Validators.maxLength(360)],
      ],
      password: [
        '',
        [
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(24),
        ],
      ],
      server: ['SECONIZE'],
    });

    // create one div/span element and trigger empty click event
    // const div = this.renderer.createElement('div');
    // this.renderer.addClass(div, 'seconize-psuedo-click');
    // // this.renderer.setStyle(div, 'display', 'none');
    // this.renderer.appendChild(document.body, div);
    // div.click();

    // this.loginForm.get('email')?.valueChanges.subscribe((email: string) => {
    //   if (email.toLowerCase() === 'support@drc.seconize.co') {

    //   } else {
    //     this.loginForm.removeControl('server');
    //   }
    // });
  }

  private modifyGoogleButtonText(): void {
    const googleButtonText = this.renderer.selectRootElement(
      '.nsm7Bb-HzV7m-LgbsSe-BPrWId',
      true
    );
    if (googleButtonText) {
      this.renderer.setProperty(googleButtonText, 'textContent', 'Google');
    }
  }

  ngAfterViewInit(): void {
    this.modifyGoogleButtonText();
  }

  ngOnInit(): void {
    // (window as any)['handleCredentialResponse'] = (response: any) => {
    //   // Handle sign-in response
    //   const userObject = JSON.parse(atob(response.credential.split('.')[1]));
    //   console.log('User signed in:', userObject);
    // };
    // Initializing login form
    // this.initLoginForm();
    this.dialog.closeAll();

    // this.loginForm.get('email')?.valueChanges.subscribe((value: string) => {
    //   this.showDropdown = value.toLowerCase() === 'support@drc.seconize.co';
    //   if (this.showDropdown) {
    //     this.loginForm.addControl(
    //       'server',
    //       this.fb.control('', Validators.required)
    //     );
    //   } else {
    //     this.loginForm.removeControl('server');
    //   }
    // });

    // if (!this.isASMBranch) {
    this.executeInvisibleRecaptcha();
    this.recaptchaInterval = setInterval(() => {
      this.executeInvisibleRecaptcha();
      this.modifyGoogleButtonText();
    }, 60000);

    this.isAuthenticated$ = this._oktaStateService.authState$.pipe(
      filter((s: AuthState) => !!s),
      map((s: AuthState) => s.isAuthenticated ?? false)
    );

    // OKTA SSO Login
    this._oktaStateService.authState$.subscribe((data: any) => {
      this.isPageLoading = false;
      if (data.isAuthenticated) {
        let payload = {
          ssoToken: data.idToken.idToken,
          ssoType: 'OKTA',
          accessToken: data.accessToken.accessToken,
        };

        this.loginService
          .SSOLogin(payload, data.idToken.claims.email)
          .subscribe((data) => {
            this.isSSO = true;
            this.isPageLoading = false;
            this.parseLogin(data);
          });
      }
    });

    // Azure SSO Login Flow
    this.isSSO = false;
    this.isIframe = window !== window.parent && !window.opener;

    // this.authService.loginPopup()
    // .subscribe({
    //   next: (result) => {
    //     console.log(result);
    //     this.setLoginDisplay();
    //   },
    //   error: (error) => console.log(error)
    // });

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType == EventType.LOGIN_SUCCESS)
      )
      .subscribe((result: any) => {
        this.isPageLoading = true;
        this.spinner.show('main');
        if (result.eventType == 'msal:loginSuccess') {
          // this.loginService.getMe(result.payload!.accessToken);
          this.loginService
            .getMicroSoftUsers(result.payload.accessToken)
            .subscribe((res) => {
              //Azure users 
              console.log(res);
            });
          this.loginService
            .getImage(result.payload!.accessToken, result.payload!.uniqueId)
            .subscribe(
              (res) => {
                const image = res;
                let reader: any = new FileReader();
                reader.addEventListener(
                  'load',
                  () => {
                    this.profileImageUrl = reader.result;
                    let userData = JSON.parse(
                      localStorage.getItem('userData') || ''
                    );
                    userData['profileImageUrl'] = this.profileImageUrl;
                    localStorage.setItem('userData', JSON.stringify(userData));
                    // const imgRes: any = reader.result;
                    // const imageToShow = this.domSanitizer.bypassSecurityTrustUrl(imgRes);
                  },
                  false
                );
                if (image) {
                  reader.readAsDataURL(image);
                }

                const urlCreator = window.URL;
                const imageUrl = urlCreator.createObjectURL(res);
              },
              (err) => {}
            );
          let payload = {
            ssoToken: result.payload!.accessToken,
            ssoType: 'AZURE_AD',
            accessToken: '',
          };
          this.loginService
            .SSOLogin(payload, result.payload!.account!.username)
            .subscribe((data) => {
              this.isSSO = true;
              this.isPageLoading = false;
              this.parseLogin(data);
            });
        } else {
          this.isPageLoading = false;
          this.isSSO = false;
          this.toaster.error('Failed to Login', '', 3000);
        }
      });

    // Google SSO Login
    this.g_authService.authState.subscribe((user) => {
      this.user = user;
      this.profileImageUrl = user.photoUrl;
      if (user) {
        this.isPageLoading = true;
        this.spinner.show('main');
        // this.loginService.getGoogleUsers(user.idToken).subscribe(
        //   (res) => {},
        //   (err) => {}
        // );
        let payload = {
          ssoToken: user.idToken,
          ssoType: 'GOOGLE',
          accessToken: user.idToken,
        };
        this.loginService.SSOLogin(payload, user.email).subscribe((data) => {
          this.isSSO = true;
          this.isPageLoading = false;
          this.parseLogin(data);
        });
      }
    });

    this.g_authService.signIn(GoogleLoginProvider.PROVIDER_ID);
    // }
  }

  ///////////////  DEFAULT LOGIN START/////////////////////

  userLogin() {
    this.isLoginDisabled = true;
    if (
      this.loginForm.errors ||
      this.loginForm.invalid ||
      this.utils.statusCode == 0
    ) {
      this.enableSubmitButton = false;

      if (this.utils.statusCode == 0) {
        this.toaster.error('Please check your Internet connection', '', 3000);
      }
      return;
    }

    this.isSSO = false;
    this.isPageLoading = true;
    this.spinner.show('main');

    localStorage.setItem('loginUserName', this.loginForm.value.email);
    localStorage.setItem('loginUserPassword', this.loginForm.value.password);
    localStorage.setItem('loginUserRecaptcha', this.reCaptchaResponse);
    let payload: any = {
      username: this.loginForm.value.email,
      password: this.loginForm.value.password,
      recaptcha: this.reCaptchaResponse,
    };
    if (this.loginForm.value.server) {
      payload.server = this.loginForm.value.server;
      localStorage.setItem('supportUserServer', this.loginForm.value.server);
    }
    // if (this.isASMBranch) {
    //   this.loginService.userLoginWithoutCaptcha(payload).subscribe(
    //     (data) => {
    //       this.isPageLoading = false;
    //       this.parseLogin(data);
    //     },
    //     () => (this.isLoginDisabled = false)
    //   );
    // } else {
    this.loginService.userLogin(payload).subscribe(
      (data) => {
        this.isPageLoading = false;
        this.parseLogin(data);
      },
      () => (this.isLoginDisabled = false)
    );
    // }
  }

  /////////////// DEFAULT LOGIN END //////////////////

  /////////////// OKTA AUTHENTICATION START ////////////////
  public isAuthenticated$!: Observable<boolean>;

  public async oktaLogin(): Promise<void> {
    await this._oktaAuth.signInWithRedirect();
  }
  public async signIn(): Promise<void> {
    await this._oktaAuth.signInWithRedirect();
  }

  public async oktaLogout(): Promise<void> {
    await this._oktaAuth.signOut();
  }

  /////////////// OKTA AUTHENTICATION END ////////////////

  public azureAdLogin() {
    this.authService.loginRedirect();
  }

  //////////////// RE CAPTCHA START ////////////////
  private executeInvisibleRecaptcha(): void {
    this.recaptchaV3Service.execute('login').subscribe((token) => {
      this.reCaptchaResponse = token;
      this.isLoginDisabled = false;
      this.checkAutoFilledValues();
      // console.log('login form', this.loginForm.value);
    });
  }

  public onError(errorDetails: RecaptchaErrorParameters): void {
    this.reCaptchaResponse = '';
    this.toaster.error('reCAPTCHA error encountered', '', 5000);
  }

  //////////////// RE CAPTCHA END ///////////////////

  // initLoginForm() {
  // loginForm = this.fb.group({
  //     email: [
  //       '',
  //       [Validators.required, Validators.email, Validators.maxLength(360)],
  //     ],
  //     password: [
  //       '',
  //       [
  //         Validators.required,
  //         Validators.minLength(8),
  //         Validators.maxLength(24),
  //       ],
  //     ],
  //   });
  // }

  checkAutoFilledValues() {
    const matFormFields =
      this.el.nativeElement.querySelectorAll('.p-inputtext');

    // console.log('matFormFields', matFormFields);

    let autoCompleteClassCount = 0;

    matFormFields.forEach((matFormField: HTMLElement) => {
      // if (matFormField.classList.contains('mat-form-field-autofilled')) {
      if (matFormField.classList.contains('p-filled')) {
        autoCompleteClassCount++;
      }
    });

    // console.log('autoCompleteClassCount', autoCompleteClassCount);

    if (
      !this.loginForm.controls['email'].hasError('email') &&
      autoCompleteClassCount == 2
    ) {
      this.enableSubmitButton = true;
    } else {
      this.enableSubmitButton = false;
    }
  }

  parseLogin(data: any) {
    this.singleBu = false;

    if (data.success) {
      var parsedToken = JSON.parse(atob(data.data.token.split('.')[1]));
      clearInterval(this.recaptchaInterval);

      if (parsedToken.authPending && !this.isSSO) {
        localStorage.setItem('token', data.data.token);
        this.is2FAPage = true;
        this.router.navigate(['2fa-verification'])
        return;
      }
      this.token = data.data.token;
      this.user = data.data.user;
      if (this.profileImageUrl) {
        this.user['profileImageUrl'] = this.profileImageUrl;
      }

      // this.privileges = JSON.stringify(data.data.user.userPrivileges);
      if (this.token != null) {
        localStorage.removeItem('loginUserName');
        localStorage.removeItem('loginUserPassword');
        localStorage.removeItem('loginUserRecaptcha');
        localStorage.removeItem('token');
        localStorage.setItem('email', this.loginForm.value.email);
        localStorage.setItem('loggedInUserId', this.user.id);
        localStorage.setItem('loggedInUserName', this.user.userName);
        localStorage.setItem('name', this.user.firstName);
        localStorage.setItem('UserRoles', this.user.roles);
        localStorage.setItem('userData', JSON.stringify(this.user));
        this.loginService.setUserLoggedIn();
        localStorage.setItem('token', this.token);
        // localStorage.setItem('privileges', this.privileges);
        // localStorage.setItem('UserScope', this.user.scope);
        this.getAllPermissions(this.user.id);

        // code for Google analytics and Google tag manager//
        let user_id = localStorage.getItem('loggedInUserName');
        (<any>window).dataLayer = (<any>window).dataLayer || [];
        (<any>window).dataLayer.push({
          event: 'login',
          user_id: user_id,
          debug_mode: true,
          env: environment.env,
          properties: {
            user_id_dimension: user_id,
            env: environment.env,
          },
        });
        // code for Google analytics and Google tag manager//
        let api = '/identity/users/license';
        this.genericService.getRequest(api, false).subscribe((data: any) => {
          if (data.success) {
            this.singleBu = data.data.singleBu;
            localStorage.setItem('singleBu', this.singleBu.toString());
            localStorage.setItem('asm', JSON.stringify(data.data.asm));
            localStorage.setItem(
              'vulnMgmt',
              JSON.stringify(data.data.vulnMgmt)
            );
            localStorage.setItem(
              'subscription',
              JSON.stringify(data.data.subscription)
            );
            localStorage.setItem(
              'assetToCntMap',
              JSON.stringify(data.data.assetToCntMap)
            );
            localStorage.setItem(
              'assetUsageToCntMap',
              JSON.stringify(data.data.assetUsageToCntMap)
            );
            localStorage.setItem(
              'complianceMgmt',
              JSON.stringify(data.data.complianceMgmt)
            );
            localStorage.setItem(
              'internalAudit',
              JSON.stringify(data.data.internalAudit)
            );
            localStorage.setItem(
              'inboundAudit',
              JSON.stringify(data.data.inboundAudit)
            );
            localStorage.setItem(
              'vendorRiskMgmt',
              JSON.stringify(data.data.vendorRiskMgmt)
            );
            localStorage.setItem('Logo', JSON.stringify(data.data.logo));
          }
          const redirectUrl = this.redirectService.getRedirectUrl();
          console.log('redirect url', redirectUrl);
          if (localStorage.getItem('UserRoles') == 'SZE_ADMIN') {
            // this.router.navigate(['/tenant']);
            // console.log('Super admin called');
            this.router.navigate([
              environment.routePrefix
                ? '/' + environment.routePrefix + '/tenant'
                : '/tenant',
            ]);
          } else if (redirectUrl && redirectUrl != '/') {
            // console.log('redirect url');
            this.router.navigateByUrl(redirectUrl);
          } else {
            // console.log('Called');
            this.router.navigate(['']);
            this.utils.routeTo(true);
          }
        });

        // this.getAllPermissions(this.user.id);
      }
      // this.isLoginDisabled = false;
    } else {
      if (data.message.includes('Password Expired')) {
        let page_url = '/auth/resetPassword?' + data.data + '&expired=true';
        this.router.navigateByUrl(
          environment.routePrefix
            ? '/' + environment.routePrefix + page_url
            : page_url
        );
        return;
      }
      this.toaster.error(data.message, '', 3000);
      this.isLoginDisabled = false;
    }
  }
  navigateToForgotPassword() {
    let page_url = '/auth/forgot-password';
    this.router.navigateByUrl(
      environment.routePrefix
        ? '/' + environment.routePrefix + page_url
        : page_url
    );
  }

  emit2FA(val: any) {
    this.is2FAPage = val;
  }

  private getAllPermissions(userId: string) {
    let api = `/identity/users/${userId}/permissions/search/`;
    this.genericService.getRequest(api).subscribe((data: any) => {
      if (data.success) {
        localStorage.setItem(
          'permissions',
          JSON.stringify(data.data.userPermissions)
        );
      }
    });
  }

  // -----------------------------------------------

  checkError(field: string) {
    // check form is dirty or not
    if (!this.loginForm.controls[field].touched) {
      return '';
    }

    console.log(this.loginForm);
    if (field === 'email') {
      if (this.loginForm.controls[field].hasError('required')) {
        return 'User ID is required';
      } else if (this.loginForm.controls[field].hasError('email')) {
        return 'Please enter a valid User ID';
      } else if (this.loginForm.controls[field].hasError('maxlength')) {
        return 'User ID cannot exceed 360 characters';
      } else {
        return '';
      }
    } else if (field === 'password') {
      if (this.loginForm.controls[field].hasError('required')) {
        return 'Password is required';
      } else if (this.loginForm.controls[field].hasError('minlength')) {
        return 'Password must be at least 8 characters';
      } else if (this.loginForm.controls[field].hasError('maxlength')) {
        return 'Password cannot exceed 24 characters';
      } else {
        return '';
      }
    } else {
      return '';
    }
  }

  emailValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const email = control.value;
      const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      const specialCharacterPattern = /^[^!#$%^&*()+=\[\]\';,\/{}|":<>?\\`~]*$/;

      if (!emailPattern.test(email)) {
        return { email: true };
      }
      if (!specialCharacterPattern.test(email)) {
        return { invalidCharacters: true };
      }
      return null;
    };
  }
}
